<template>
  <div>
    <div>
      <div class="item1" @click="down">
        <img v-lazy="require('@/assets/images/app/Applogo-bac1.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac2.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac3.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac4.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac5.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac6.png')" alt="shopimg" class="item_con">
      </div>
      <div class="item1">
        <img v-lazy="require('@/assets/images/app/Applogo-bac7.png')" alt="shopimg" class="item_con">
      </div>
    </div>

    <div class="bottom">
      <div class="bottom_outer">
        <div class="bottom_titleText" style="padding-top: 30px">
          声明
        </div>
        <div class="bottom_titleCon" style="padding-top: 8px" @click="privacy">
          隐私政策
        </div>

        <div class="bottom_titleText" style="padding-top: 39px">
          服务与合作
        </div>
        <div class="cooperate">
          <div class="bottom_titleCon" style="padding-top: 8px">
            商家入驻
          </div>
          <div class="bottom_titleCon" style="padding-top: 8px">
            赛事赞助
          </div>
          <div class="bottom_titleCon" style="padding-top: 8px">
            融资对接
          </div>
          <div class="bottom_titleCon" style="padding-top: 8px">
            品牌联名
          </div>
          <div class="bottom_titleCon" style="padding-top: 8px">
            知识产权
          </div>
        </div>

        <div class="bottom_titleText" style="padding-top: 39px">
          联系我们
        </div>
        <div style="padding-top: 8px;display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: end;">
            <img v-lazy="require('@/assets/images/app/contact.png')" alt="shopimg" style="width: 96px;height: 96px;">
            <div class="bottom_titleCon" style="margin-left: 10px;">企微客服</div>
          </div>
          <div style="display: flex;align-items: end;">
            <img v-lazy="require('@/assets/images/app/contact1.png')" alt="shopimg" style="width: 96px;height: 96px;">
            <div class="bottom_titleCon" style="margin-left: 10px;">公众号</div>
          </div>
        </div>

        <div class="bottom_titleCon" style="padding-top: 39px;display: flex;justify-content: center;">
          广州锐思云信息科技有限公司
        </div>
        <div class="bottom_titleCon" style="padding-top: 17.5px;font-size: 9px;display: flex;justify-content: center;">
          Copyright 2023-2024 Guangzhou Ruisiyun Information Technology Co., Ltd.
        </div>
        <div class="bottom_titleCon"
          style="padding-top: 17.5px;display: flex;justify-content: center;padding-bottom: 24px;" @click="filings">
          粤ICP备2023063105号
        </div>
      </div>
    </div>

    <div class="wx" v-if="weixin" @click="weixin = false"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        weixin: false, //是否为微信内浏览器打开
      };
    },
    methods: {
      downTest() {
        window.location.href = "https://apps.apple.com/app/id6462408062";
      },
      down() {
        const u = navigator.userAgent.toLowerCase();
        const isWeixin = u.includes('micromessenger'); // 检测是否在微信内浏览器中打开
        const isAndroid = u.includes('android'); // 检测是否为Android终端
        const isIOS = /(iphone|ipad|ipod|ios)/i.test(u); // 检测是否为iOS终端
        const isPc = u.includes('windows'); // 检测是否为PC终端
        const isMacPc = u.includes('macintosh'); // 检测是否为MacPC终端

        // 微信内浏览器
        if (isWeixin) {
          this.weixin = true;
        } else if (isAndroid) {
          window.location.href =
            "http://file.market.xiaomi.com/download/AppStore/033d8482d4ec44f6abaf3e65e8f4dc945c0e8d9f3/com.ruisi.mall_4.5.0.apk";
          return;
          // // Android终端
          // if (u.includes('vivo')) {
          //   window.location.href =
          //     "http://file.market.xiaomi.com/download/AppStore/016d522e9f1b84f46a9201c2541debc3b48914d4b/com.ruisi.mall_4.1.2.apk";
          //   return;
          // } else if (u.includes('xiaomi')) {
          //   window.location.href = "http://file.market.xiaomi.com/download/AppStore/016d522e9f1b84f46a9201c2541debc3b48914d4b/com.ruisi.mall_4.1.2.apk";
          //   return;
          // } else if (u.includes('huawei')) {
          //   window.location.href = "http://file.market.xiaomi.com/download/AppStore/016d522e9f1b84f46a9201c2541debc3b48914d4b/com.ruisi.mall_4.1.2.apk";
          //   return;
          // } else {
          //   window.location.href = "http://file.market.xiaomi.com/download/AppStore/016d522e9f1b84f46a9201c2541debc3b48914d4b/com.ruisi.mall_4.1.2.apk";
          //   return;
          // }
        } else if (isIOS) {
          // iOS终端
          setTimeout(() => {
            if (!document.hidden) {
              this.downTest();
            }
          }, 500);
          return;
        } else if (isPc) {
          // PC终端
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ruisi.mall';
          return;
        } else if (isMacPc) {
          // MacPC终端
          this.downTest();
          return;
        } else {
          // 其他设备类型，可以给出提示或引导用户进行下载
          alert('请在支持的设备上打开下载');
        }
      },

      // 隐私政策点击
      privacy() {
        window.open('https://www.kdocs.cn/l/ciQE2MdhsHvK?from=docs&startTime=1716171672944&createDirect=true&newFile=true')
      },
      // 备案地址点击
      filings(){
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    }
  };
</script>

<style scoped>
  .item1 {
    width: 100%;
  }

  .item_con {
    width: 100%;
  }

  .bottom {
    width: 100%;
    background-color: #000000;
  }

  .bottom_outer {
    width: 88%;
    margin: 0 auto;
  }

  .bottom_titleText {
    color: #DF64FF;
    font-size: 12px;
    font-weight: 700;
  }

  .bottom_titleCon {
    color: #FFFFFF;
    font-size: 12px;
  }

  .cooperate {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wx {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    background-color: rgba(0, 0, 0, .7);
    width: 100vw;
    height: 100vh;
    background-image: url('@/assets/images/app/wx.png');
    background-size: 169px 150px;
    background-position: right 30px top 10px;
    background-repeat: no-repeat;
  }
</style>